@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
  margin: 0;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* plus-jakarta-sans-cyrillic-ext-400-normal*/
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/plus-jakarta-sans-cyrillic-ext-400-normal.c919db3a.woff2) format('woff2'), url(/_next/static/media/plus-jakarta-sans-all-400-normal.3048b011.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* plus-jakarta-sans-vietnamese-400-normal*/
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/plus-jakarta-sans-vietnamese-400-normal.2044eddd.woff2) format('woff2'), url(/_next/static/media/plus-jakarta-sans-all-400-normal.3048b011.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* plus-jakarta-sans-latin-ext-400-normal*/
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/plus-jakarta-sans-latin-ext-400-normal.e9d20212.woff2) format('woff2'), url(/_next/static/media/plus-jakarta-sans-all-400-normal.3048b011.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* plus-jakarta-sans-latin-400-normal*/
@font-face {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/plus-jakarta-sans-latin-400-normal.fdf86c30.woff2) format('woff2'), url(/_next/static/media/plus-jakarta-sans-all-400-normal.3048b011.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

